import KnMiniFooter from '../../components/KnMiniFooter/KnMiniFooter.vue';
export default {
  name: 'EmptyLayout',
  components: {
    KnMiniFooter,
  },
  data() {
    return {};
  },
};
